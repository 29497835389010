import React from 'react'
import createReactClass from 'create-react-class'

import ps from '../../../../themes/components/Wapplrpost.css'
import avs from '../../../../themes/components/Wapplravatar.css'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'
import Wapplrdialog from '../../../components/Wapplrdialog'

import fetchs from '../../../services/fetchs'

import { Step, StepButton, StepLabel, Stepper } from 'material-ui/Stepper'

const WapplrpostEdit = wapplrcomponents.editpost

const Post = createReactClass({
    getInitialState: function() {
        return {
            isMounted: false,
            post: null,
            stepIndex: 0
        }
    },
    componentDidMount: function() {
        const { response, curlang = {}, history } = this.props

        const {
            savepostdefaultfail = 'Sorry, there was an issue save your post, please try again',
            savepostsuccessmessage = 'Your post has been saved'
        } = curlang

        const post = this.getPost(response)
        const getPostLink = this.getPostLink

        const historyPush = this.historyPush
        const snack = this.snack

        this.savefetch = fetchs.newnotification({
            getHelpers: this.getHelpers,
            history: history,
            success: function({ response }) {
                snack(savepostsuccessmessage)
                setTimeout(function() {
                    historyPush(getPostLink({ id: response.postId }))
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || savepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })



        this.setState({
            isMounted: true,
            post: post
        })

    },

    //Close

    close: function() {

        const { state } = this.getHelpers()

        const getUserLink = this.getUserLink
        const historyPush = this.historyPush

        if (state.lasturl) {
            historyPush(state.lasturl)
        } else {
            historyPush(getUserLink())
        }
    },

    //Edit functions

    savefetch: function() {
    },
    savepost: function(data, resetForm, invalidateForm, refs) {
        const { stepIndex, saveddata = {}, post } = this.state
        const stepdata = (this.stepdata) ? this.stepdata : {}

        const rdata = {
            ...saveddata,
            ...data
        }

        rdata.id = (post && post.id) ? post.id : 0

        this.stepdata = {
            ...stepdata,
            [stepIndex]: {
                data,
                invalidateForm
            }
        }

        const rinvalidateForm = this.invalidateForm
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) processingStart(true)
        this.savefetch(rdata, resetForm, rinvalidateForm, refs)
    },
    invalidateForm: function(messages = {}) {

        const stepdata = (this.stepdata) ? this.stepdata : {}
        const messagesbystep = {}

        let foundstep = false

        Object.keys(messages).map(function(field) {
            Object.keys(stepdata).map(function(stepindex) {
                const step = stepdata[stepindex]
                const data = step.data
                if (data) {
                    Object.keys(data).map(function(v) {
                        if (v === field) {
                            if (!messagesbystep[stepindex]) {
                                if (foundstep === false) foundstep = stepindex
                                messagesbystep[stepindex] = {}
                            }
                            messagesbystep[stepindex][field] = messages[field]
                        }
                    })
                }
            })
        })

        if (foundstep === false && foundstep !== 0 && foundstep !== '0') {
            const snack = this.snack
            let rm = ''
            Object.keys(messages).map(function(m) {
                rm = (!rm) ? messages[m] : rm + '; ' + messages[m]
            })
            if (rm) snack(rm)
        } else {

            const rmessages = messagesbystep[foundstep]
            this.setState({
                stepIndex: Number(foundstep)
            })
            const refElements = this.refElements
            setTimeout(function() {
                const formsy = refElements['formsy_formsy']
                if (formsy && formsy.updateInputsWithError) formsy.updateInputsWithError(rmessages)
            }, 500)
        }

    },
    nextstep: function(data) {
        const { stepIndex, saveddata = {} } = this.state
        const stepdata = (this.stepdata) ? this.stepdata : {}

        this.stepdata = {
            ...stepdata,
            [stepIndex]: {
                data
            }
        }

        this.setState({
            stepIndex: stepIndex + 1,
            saveddata: {
                ...saveddata,
                ...data
            }
        })
    },
    getstep: function(step) {
        const { stepIndex } = this.state
        if (stepIndex > step) {
            this.setState({
                stepIndex: Number(step)
            })
        }
    },

    //notification helpers
    getPostLink: function(ipost) {
        const post = ipost || this.state.post
        if (post && post.id) {
            return '/notification/' + post.id
        }
        return ''
    },
    getPost: function() {
        return {
            id: 1,
            title: ''
        }
    },
    getUserLink: function() {
        const me = this.getMe()
        if (me && me.id) {
            return '/user/' + me.id
        }
        return ''
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    refElements: {},
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getEditData: function() {
        const { editdata } = this.props
        const { stepIndex} = this.state
        return editdata.notification.new[stepIndex]
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },

    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    render: function() {

        const { history, curlang = {}, getthumb, getthumbstyle } = this.props

        const {
            stepsetbasicdata = 'Set basic data'
        } = curlang

        const savepost = this.savepost
        const nextstep = this.nextstep
        const data = this.getEditData()
        const state = this.state
        const close = this.close
        const setRef = this.setRef
        const me = this.getMe()
        const { fetch } = this.getHelpers()
        const { stepIndex, post } = state
        const getstep = this.getstep

        return (
            <div>
                {(me && me.id) ?
                    <div>
                        <div>
                            <Stepper activeStep={stepIndex}>
                                <Step>
                                    <StepButton onClick={function() {
                                        getstep(0)
                                    }}>
                                        <StepLabel>{stepsetbasicdata}</StepLabel>
                                    </StepButton>
                                </Step>
                                <Step />
                            </Stepper>
                        </div>
                        <div>
                            <WapplrpostEdit history={history} style={ps} post={post} showauthor={true} avatarstyle={avs}
                                            save={(stepIndex < 0) ? nextstep : savepost}
                                            data={data}
                                            close={close}
                                            getthumb={getthumb}
                                            getthumbstyle={getthumbstyle}
                                            formprops={{
                                                user: me, fetch: fetch, setRef: function(a, e) {
                                                    setRef('formsy_' + a, e)
                                                }
                                            }}
                            />

                        </div>
                    </div>
                    : null
                }
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
                <Wapplrdialog setRef={setRef} className={ps.dialog} />
            </div>
        )
    }
})

export default createReactClass({
    render: function() {

        return (
            <TemplateDefault {...this.props}>
                <Post {...this.props} />
            </TemplateDefault>
        )
    }
})
