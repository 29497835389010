export default {
    postnotificationtitle: 'Notification',
    menunotificationtitle: 'Notification',
    editnotificationtitle: 'Edit notification',
    newnotificationtitle: 'New notification',
    usernotificationsssubtitle: 'Notifications',
    usernotificationsstrashsubtitle: 'Deleted notifications',
    wapplruserappnotificationsname: 'Notifications',
    wapplruserappnotificationstitle: 'User notifications',
    wapplruserappnotificationstrash: 'Deleted notifications',

    notifications: {
        changeworkstate: function({ post }) {
            let r = 'Someone changed to your player work state'
            const secuser = post.secuser
            if (secuser && secuser.id && secuser.name && secuser.name.first) {
                r = secuser.name.first + ' changed to your player work state'
            }

            return r
        },
        changeworkstate_content: function({ data }) {
            let r = ''
            r = r + '<p style=\'margin: 0!important;\'><span style=\'font-weight:bold\'>Player title: </span><span>' + data.title + '</span></p>'
            if (data.workstate) r = r + '<p style=\'margin: 0!important;\'><span style=\'font-weight:bold\'>Work state: </span><span>' + data.workstate + '</span></p>'
            return r
        },
        scriptrunned: function({ post }) {
            let r = 'Automation script was runned on your player'
            if (post && post.secpostid) r = 'Automation script was runned on your player (' + post.secpostid + ')'
            return r
        },
        scriptrunned_content: function({ data }) {
            let r = ''
            r = r + '<p style=\'margin: 0!important;\'><span style=\'font-weight:bold\'>Response: </span><span>' + data.response + '</span></p>'
            if (data.previousData) r = r + '<p style=\'margin: 0!important;\'><span style=\'font-weight:bold\'>Previous data: </span><span>' + data.previousData + '</span></p>'
            return r
        }
    },

    notificationsearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Search in notifications'
        if (username && !taxs) r = 'Search in ' + username + '\'s notifications'
        if (!username && taxs) r = 'Search in notifications in ' + taxs + ' category'
        if (username && taxs) r = 'Search in ' + username + '\'s notifications, and in ' + taxs + ' category'
        return r
    },

    metastatusofreaded: 'Read',
    metareaded: 'read',
    metaunread: 'unread',
    metanotificationdate: 'Notification date',
    metaposturl: 'Post url',
    metaclick: 'click'
}
