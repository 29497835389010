import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'

import avs from '../../../../themes/components/Wapplravatar.css'
import gs from '../../../../themes/components/Wapplrgallery.css'
import ps from '../../../../themes/components/Wapplrpost.css'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

import { poststatuses } from '../../../server/poststatus/statuses'
import imageContent from '../../image/contents/content'
import Slider from '../../../components/Slider'

import MetaData from '../../../components/MetaData'
import mds from '../../../components/MetaData/PostData.css'
import TimeIcon from 'material-ui/svg-icons/device/access-time'
import EyeIcon from 'material-ui/svg-icons/image/remove-red-eye'

import { decode } from 'querystring'
import fetchs from '../../../services/fetchs'

const Wapplrpost = wapplrcomponents.post
const Wapplrgallery = wapplrcomponents.gallery
const WapplrAvatar = wapplrcomponents.avatar

export default createReactClass({
    getInitialState: function() {
        const key = this.getKey()
        return {
            isMounted: false,
            post: null,
            key: key || null
        }
    },
    getKey: function() {
        const state = this.getGlobalState()
        if (state && state.query && state.query.resetkey) return state.query.resetkey

        const { history } = this.props
        let search = (history && history.location) ? history.location.search : ''
        if (search.slice(0, 1) === '?') search = search.slice(1)
        const so = (search) ? decode(search) : {}
        return so.feedback
    },
    postNormalise: function({ post }) {

        const { curlang = {} } = this.props
        if (!curlang.notifications) curlang.notifications = {}
        const notifications = curlang.notifications

        const nt = post.notificationtype
        if (nt) {
            if (notifications[nt]) post.title = notifications[nt]({ post })
        }
        if (post.secposturl) post.link = { pathname: post.secposturl }

        return post
    },
    getPost: function(response) {
        if (response && response.currentnotification && response.currentnotification[0]) response.currentnotification[0] = this.postNormalise({ post: response.currentnotification[0] })
        const post = (response && response.currentnotification && response.currentnotification[0]) ? response.currentnotification[0] : null
        if (post && post.content_extended) {
            let v = post.content_extended
            if (v && v.replace) {
                v = v.replace(/(\r\n|\n|\r)/gm, '<br />')
            }

            post.content_extended = v
        }
        return post
    },
    getPostLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/notification/' + post.id
        }
        return ''
    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    componentDidMount: function() {

        const { response, history, curlang = {} } = this.props
        const post = this.getPost(response)

        const {
            feedbackdefaultfail = 'Sorry, there was an issue confirm notification, please try again',
            feedbacksuccess = 'Thank you for you feedback'
        } = curlang

        this.setState({
            isMounted: true,
            post: post
        })

        const getPostLink = this.getPostLink
        const historyPush = this.historyPush
        const snack = this.snack
        const key = this.state.key

        if (key && post && post.id) {

            fetchs.feedbacknotification({
                getHelpers: this.getHelpers,
                history: history,
                success: function() {
                    snack(feedbacksuccess)
                    setTimeout(function() {
                        historyPush(getPostLink())
                    }, 1500)
                },
                fail: function({ response}) {
                    if (response.message) {
                        const message = response.message || feedbackdefaultfail
                        snack(message)
                    }
                }
            })({
                id: post.id,
                feedback: key
            })

        }


    },
    getPostEditLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/notification/edit/' + post.id
        }
        return ''
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    setStatusName: function({ statusname }) {
        let s = statusname
        const me = this.getMe()
        const admin = me && me.isAdmin || me && me.isEditor

        if (!admin) {
            if (s === 'deleted itself') {
                s = 'deleted'
            }
            if (s === 'created') {
                s = 'published'
            }
            if (s === 'required data') {
                s = 'published'
            }
            if (s === 'approved') {
                s = 'published'
            }
        }

        return s
    },
    getPostStatus: function() {
        const post = this.state.post
        if (post && post.id) {
            const deleteorrestorepoint = poststatuses['created']
            const currentstatus = post.status || deleteorrestorepoint
            const deleted = (currentstatus < deleteorrestorepoint)

            let statusname = 'created'
            Object.keys(poststatuses).map(function(psk) {
                if (poststatuses[psk] === currentstatus) statusname = psk
            })
            statusname = this.setStatusName({ statusname })

            return { statusname: statusname, poststatus: post.status, deleted }
        }
        return { statusname: 'not found', poststatus: poststatuses['banned'] - 1 }
    },

    //Publicicon
    publicicon: function({ post }) {

        const share = post && post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },
    openGallery: function(e, post) {

        const refElements = this.refElements
        const wapplrgallery = refElements['wapplrgallery']
        if (wapplrgallery && wapplrgallery.modalopen) {
            wapplrgallery.modalopen()
        }

        setTimeout(function() {
            const slider = refElements['slider']
            if (slider && slider.setById) {
                slider.setById(post.id)
            }
        })
    },
    getModal: function({ state, modalclose, style, setRef}) {

        const { curlang } = this.props
        const {
            closetext = 'Close'
        } = curlang
        const post = this.state.post
        const notificationProps = this.props

        const actions = [
            <FlatButton
                label={closetext}
                onTouchTap={modalclose}
            />
        ]

        const SliderParent = createReactClass({
            render: function() {
                const { children } = this.props
                return (
                    <div>
                        {children}
                    </div>
                )
            }
        })

        if (post && post.gallery && post.gallery.length) {

            const slides = []
            post.gallery.map(function(image) {
                const s = {
                    component: imageContent,
                    props: {
                        ...notificationProps,
                        Parent: SliderParent,
                        response: {
                            currentimage: [
                                image
                            ]
                        }
                    },
                    id: image.id
                }
                slides.push(s)
            })

            return (
                <Dialog
                    ref={(setRef) ? function(e) {
                        setRef('dialog', e)
                    } : null}
                    title={post.title}
                    modal={false}
                    actions={actions}
                    open={state.modalopen}
                    onRequestClose={modalclose}
                    autoScrollBodyContent={true}
                    className={style.gallerymodal}
                >
                    <Slider slides={slides} setRef={setRef} />
                </Dialog>
            )
        } else {
            return null
        }
    },

    refElements: {},
    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },

    getData: function() {

        const {
            curlang = {}, history, getthumb, getthumbstyle
        }
            = this.props


        function gourl(url) {
            history.push({ pathname: url })
        }

        const {
            viewprofile = 'view profile'
        } = curlang

        const data = [
            {
                value: function({ post, style }) {
                    if (post && post.secuser && post.secuser.id) {
                        return (
                            <div className={style.avatarinmeta}>
                                <WapplrAvatar
                                    user={post.secuser}
                                    style={avs}
                                    type='list'
                                    enableUserLink={true}
                                    history={history}
                                    getthumb={getthumb}
                                    getthumbstyle={getthumbstyle}
                                />
                                <div className={style.textlink + ' ' + style.paddingasvalue}
                                     onTouchTap={function() {
                                         gourl('/user/' + post.secuser.id)
                                     }}
                                     onTouchStart={function() {

                                     }}>
                                    {viewprofile}
                                </div>
                            </div>
                        )
                    }
                    return null
                }
            },
            {
                name: curlang.metaposturl, value: function({ post }) {
                    const url = post.secposturl
                    if (url) {
                        return (
                            <div
                                onTouchTap={function() {
                                    gourl(url)
                                }}
                                onTouchStart={function() {

                                }}>
                                {curlang.metaclick}
                            </div>
                        )
                    }
                    return null
                }, valuestyle: { cursor: 'pointer' }
            },
            {
                icon: <EyeIcon />, name: curlang.metastatusofreaded, value: function({ post }) {
                    const readed = (post.readed) ? curlang.metareaded : curlang.metaunread
                    return <div>{readed}</div>
                }
            },
            { icon: <TimeIcon />, name: curlang.metanotificationdate, value: 'publishedDate' }
        ]
        return data
    },

    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    render: function() {

        const { history, getthumb, searchData, style, getthumbstyle } = this.props
        const post = this.state.post
        const author = (post && post.author) ? post.author : null
        const me = this.getMe()
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)
        const edit = canEdit ? this.getPostEditLink() : null
        const { statusname, deleted } = this.getPostStatus()
        const publiciconcontent = this.publicicon({ post })
        const openGallery = this.openGallery
        const getModal = this.getModal
        const setRef = this.setRef

        const getData = this.getData

        return (
            <TemplateDefault {...this.props} >
                <Wapplrpost
                    history={history}
                    style={ps}
                    post={this.state.post}
                    showauthor={true}
                    avatarstyle={avs}
                    edit={edit}
                    statusname={(deleted) ? statusname : null}
                    publiciconcontent={publiciconcontent}
                    getthumb={getthumb}
                    getthumbstyle={getthumbstyle}
                />
                {(post && post.publishedDate) ?
                    <div className={style.postrow}>
                        <MetaData
                            post={post}
                            style={mds}
                            data={getData()}
                        />
                    </div>
                    : null
                }
                {(post && post.gallery && post.gallery.length) ?
                    <Wapplrgallery
                        setRef={setRef}
                        type={null}
                        posts={post.gallery}
                        getthumb={getthumb}
                        posttype={'Image'}
                        searchObjectName={'id'}
                        searchData={searchData}
                        style={gs}
                        onClick={openGallery}
                        modal={getModal}
                    />
                    : null
                }
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
            </TemplateDefault>
        )
    }
})
