import React from 'react'

export function editdata({ curlang}) {

    return {
        'new': {
            0: [
                {
                    type: 'text',
                    attr: {
                        name: 'authorid',
                        hintText: 'Author id',
                        floatingLabelText: 'Author id',
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.authorid = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            if (!post.authorid && post.author && post.author.id) post.authorid = post.author.id
                            return (post && post.authorid) ? post.authorid : ''
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'title',
                        hintText: curlang.edithinttitle,
                        floatingLabelText: curlang.edithinttitle,
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.title = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.title) ? post.title : ''
                        }
                    }
                }, {
                    type: 'text',
                    attr: {
                        name: 'secpostid',
                        hintText: 'Secondary post id',
                        floatingLabelText: 'Secondary post id',
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.secpostid = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.secpostid) ? post.secpostid : ''
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'secposttype',
                        hintText: 'Secondary post type',
                        floatingLabelText: 'Secondary post type',
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.secposttype = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.secposttype) ? post.secposttype : ''
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'secposturl',
                        hintText: 'Secondary post url',
                        floatingLabelText: 'Secondary post url',
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.secposturl = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.secposturl) ? post.secposturl : ''
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'notificationtype',
                        hintText: 'Notification type',
                        floatingLabelText: 'Notification type',
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.notificationtype = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.notificationtype) ? post.notificationtype : ''
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'secuserid',
                        hintText: 'Secondary user id',
                        floatingLabelText: 'Secondary user id',
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.secuserid = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            if (!post.secuserid && post.secuser && post.secuser.id) post.secuserid = post.secuser.id
                            return (post && post.secuserid) ? post.secuserid : ''
                        }
                    }
                },
                {
                    type: 'toggle',
                    attr: {
                        name: 'readed',
                        label: 'Readed',
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post['readed'] = !!(value)
                            }
                        },
                        defaultToggled: function(state, props) {
                            const { post } = props
                            return (!(post && !post['readed']))
                        }
                    }
                },
                {
                    type: 'button',
                    attr: {
                        type: 'submit',
                        name: 'submit',
                        label: function(state, props) {
                            const { restoretext } = props
                            return (state.processing) ? curlang.savepost : (restoretext) ? restoretext : curlang.savepost
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { marginTop: 0 }
                            return { marginTop: 32 }
                        },
                        disabled: function(state = {}) {

                            return !state.canSubmit
                        },
                        secondary: true
                    }
                }
            ]
        },
        'edit': {
            0: [
                {
                    type: 'toggle',
                    attr: {
                        name: 'readed',
                        label: 'Readed',
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post['readed'] = !!(value)
                            }
                        },
                        defaultToggled: function(state, props) {
                            const { post } = props
                            return (!(post && !post['readed']))
                        }
                    }
                }, {
                    type: 'button',
                    attr: {
                        type: 'submit',
                        name: 'submit',
                        label: function(state, props) {
                            const { restoretext } = props
                            return (state.processing) ? curlang.savepost : (restoretext) ? restoretext : curlang.savepost
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { marginTop: 0 }
                            return { marginTop: 32 }
                        },
                        disabled: function(state = {}) {

                            return !state.canSubmit
                        },
                        secondary: true
                    }
                }
            ]
        }
    }
}

