import React from 'react'
import createReactClass from 'create-react-class'

import avs from '../../../../themes/components/Wapplravatar.css'
import fs from '../../../../themes/components/Wapplrfeed.css'

import Wapplrdialog from '../../../components/Wapplrdialog'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'

import wapplrcomponents from '../../../../components'

import NoMatches from '../../../components/NoMatches'
import nms from '../../../components/NoMatches/NoMatches.css'
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever'
import PublisIcon from 'material-ui/svg-icons/editor/publish'
import IconButton from 'material-ui/IconButton'
import EditIcon from 'material-ui/svg-icons/editor/mode-edit'
import DuplicateIcon from 'material-ui/svg-icons/content/content-copy'
import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'
import StatusIcon from 'material-ui/svg-icons/editor/vertical-align-center'

import Subheader from 'material-ui/Subheader'

import fetchs from '../../../services/fetchs'
import { getPostStatusData } from '../../../server/poststatus/statuses'

import MetaData from '../../../components/MetaData'
import mds from '../../../components/MetaData/MetaData.css'
import TimeIcon from 'material-ui/svg-icons/device/access-time'
import EyeIcon from 'material-ui/svg-icons/image/remove-red-eye'

import AdminPost from '../../../components/Admin/Post.js'
import adminpostfunctions from '../../../components/Admin/postfunctions.js'
import { List, ListItem } from 'material-ui/List'
import ActionInfoIcon from 'material-ui/svg-icons/action/info'
import { darkBlack } from 'material-ui/styles/colors'

const Pagination = wapplrcomponents.pagination
const WapplrAvatar = wapplrcomponents.avatar

export default createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const posts = this.getPosts()
        const postsdata = this.getPostsData()

        const posttype = this.getPostType()
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath

        return {
            isMounted: false,
            posts: posts || null,
            postsdata: postsdata || null,
            posttype: posttype || '',
            posttypepath: posttypepath || '',
            posttypename: posttypename || ''
        }
    },
    getHelpers: function() {

        const { construct } = this.props
        const { helpers } = construct.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getPostLinkObject: function({ post }) {
        if (post && post.id) {
            return {
                pathname: '/notification/' + post.id
            }
        }
        return {}
    },
    getPostLink: function({ post }) {
        if (post && post.id) {
            return '/notification/' + post.id
        }
        return ''
    },
    getPostEditLinkObject: function({ post }) {
        if (post && post.id) {
            return {
                pathname: '/notification/edit/' + post.id
            }
        }
        return {}
    },
    getPostEditLink: function({ post }) {
        if (post && post.id) {
            return '/notification/edit/' + post.id
        }
        return ''
    },
    historyPushFetch: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    setPosts: function(a) {
        this.setState({ posts: a.posts })
    },
    componentDidMount: function() {

        const { construct, history } = this.props
        const { searchData, curlang } = construct.props

        const {
            savepostdefaultfail = 'Sorry, there was an issue save your post, please try again',
            savepostsuccessmessage = 'Your post has been saved',
            deletepostdefaultfail = 'Sorry, there was an issue delete your post, please try again',
            deletepostsuccessmessage = 'Your post has been deleted',
            duplicatepostdefaultfail = 'Sorry, there was an issue duplicate your post, please try again',
            duplicatepostsuccessmessage = 'Your post has been duplicated'
        } = curlang

        this.historyHelpers = searchData.getHistoryHelpers()

        const getHelpers = this.getHelpers
        const dialogClose = this.dialogClose
        const dialogOpen = this.dialogOpen
        const snack = this.snack
        const historyPush = this.historyPushFetch
        const state = this.state
        const setPosts = this.setPosts

        adminpostfunctions({
            posttype: 'notification',
            t: this,
            curlang,
            fetchs,
            history,
            getHelpers,
            dialogClose,
            dialogOpen,
            snack,
            state,
            setState: setPosts
        })

        this.restorefetch = fetchs.savenotification({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                dialogClose()
                snack(savepostsuccessmessage)
                setTimeout(function() {
                    historyPush(history.location.pathname + history.location.search)
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || savepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.deletefetch = fetchs.deletenotification({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                dialogClose()
                snack(deletepostsuccessmessage)
                setTimeout(function() {
                    historyPush(history.location.pathname + history.location.search)
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || deletepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.duplicatefetch = fetchs.duplicatenotification({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                dialogClose()
                snack(duplicatepostsuccessmessage)
                setTimeout(function() {
                    historyPush(history.location.pathname + history.location.search)
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || duplicatepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.setState({
            isMounted: true
        })

        const clearNotificatiosNumber = this.clearNotificatiosNumber
        setTimeout(function() {
            clearNotificatiosNumber()
        }, 1500)
    },
    componentWillUnmount: function() {
        this.setState({ isMounted: false })
    },

    getPostType: function() {
        const { construct } = this.props
        const { helpers } = construct.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return state.query.posttype || ''
    },
    getPostTypePath: function() {
        const { construct } = this.props
        const { posttypesData = {} } = construct.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].path) ? posttypesData[posttype].path : ''
    },
    getPostTypeName: function() {
        const { construct } = this.props
        const { posttypesData = {} } = construct.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].pluralname) ? posttypesData[posttype].pluralname : ''
    },

    postsNormalise: function({ posts }) {

        const { construct } = this.props
        const { curlang = {} } = construct.props
        if (!curlang.notifications) curlang.notifications = {}
        const notifications = curlang.notifications

        const r = []
        posts.map(function(post) {
            if (post && post.id) {
                const nt = post.notificationtype
                if (nt) {
                    const data = (post.emailcontentdata) ?
                        (typeof post.emailcontentdata === 'object') ? post.emailcontentdata :
                            (typeof post.emailcontentdata === 'string') ? JSON.parse(post.emailcontentdata)
                                : {}
                        : {}
                    if (notifications[nt]) post.title = notifications[nt]({ post, data })
                }
                if (post.secposturl) {
                    if (post.secposturl.match(/\?/)) {
                        post.link = post.secposturl
                    } else {
                        post.link = { pathname: post.secposturl }
                    }
                }
                r.push(post)
            }
        })

        return r
    },

    addToPosts: function({ response }) {
        if (this.state.isMounted) {
            const posts = (this.state.posts && this.state.posts.length) ? [...this.state.posts] : []
            const postsdata = (this.state.postsdata) ? { ...this.state.postsdata } : {}

            if (response && response.archive && response.archive.posts) response.archive.posts = this.postsNormalise({ posts: response.archive.posts })
            const newposts = (response && response.archive && response.archive.posts) ? response.archive.posts : null
            const newpostsdata = (response && response.archive && response.archive.data) ? response.archive.data : {}

            if (newposts && newposts.length) {
                posts.push(...newposts)
                this.setState({
                    posts: posts,
                    postsdata: { ...newpostsdata, skip: postsdata.skip, skippedpages: postsdata.skippedpages }
                })
            }
        }
    },
    historyPush: function(page) {
        if (page) {
            const { store } = this.getHelpers()
            const historyHelpers = this.historyHelpers
            const h = historyHelpers.getDataFromHistory({ store })
            h.page = page
            const searchText = historyHelpers.objectToString(h)
            historyHelpers.historyPush({ search: searchText }, true)
        }
    },
    paginationOnClick: async function(e) {
        if (e) e.preventDefault()

        if (this.state.isMounted) {

            const scrollDiv = ('undefined' !== typeof document) ? (document.scrollingElement) ? document.scrollingElement : document.body : null
            const { construct } = this.props
            const { getFetch, helpers } = construct.props

            const state = (helpers && helpers.store) ? helpers.store.getState() : null
            state.query.page = (state.query.page) ? Number(state.query.page) + 1 : 2
            const response = await getFetch({ state })
            const wy = (scrollDiv && scrollDiv.scrollTop) ? scrollDiv.scrollTop : 0
            this.addToPosts({ response: response })
            if (wy) scrollDiv.scrollTop = wy

        }
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        const { state } = this.getHelpers()
        if (state) return state
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getPosts: function() {
        const state = this.getGlobalState()
        if (state && state.response && state.response.archive && state.response.archive.posts) state.response.archive.posts = this.postsNormalise({ posts: state.response.archive.posts })
        return (state && state.response && state.response.archive && state.response.archive.posts) ? state.response.archive.posts : null
    },
    getPostsData: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.archive && state.response.archive.data) ? state.response.archive.data : null
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getPostStatus: function({ post }) {
        const { construct } = this.props
        const { curlang } = construct.props
        return getPostStatusData({ post, curlang })
    },
    deletefetch: function() {
    },
    deletefunction: function(e, d) {
        const { construct } = this.props
        const { curlang = {} } = construct.props
        const { post } = d

        const {
            deletepostsubtitle = 'Delete post',
            deletepostquestion = 'Are you sure want to delete your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const deletefetch = this.deletefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: deletepostsubtitle,
            text: deletepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = { id: post.id }
                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                deletefetch(data, resetForm, invalidateForm, refs)

            }
        })

    },
    deleteicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)

        if (deleteorrestore === 'delete' && canEdit) {
            const deletefunction = this.deletefunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        deletefunction(e, { post })
                    }}
                    onTouchStart={function() {
                    }}
                    className={style.cardrightbuttonicon}
                >
                    <DeleteIcon />
                </IconButton>
            )
        }
        return null
    },
    restorefetch: function() {
    },
    restorefunction: function(e, d) {

        const { construct } = this.props
        const { curlang = {} } = construct.props
        const { post } = d

        const {
            restorepostsubtitle = 'Restore',
            restorepostquestion = 'Are you sure want to restore your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const restorefetch = this.restorefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: restorepostsubtitle,
            text: restorepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = post
                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                restorefetch(data, resetForm, invalidateForm, refs)

            }
        })
    },
    restoreicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)

        if (deleteorrestore === 'restore' && canEdit) {
            const restorefunction = this.restorefunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        restorefunction(e, { post })
                    }}
                    onTouchStart={function() {
                    }}
                    className={style.cardrightbuttonicon}
                >
                    <PublisIcon />
                </IconButton>
            )
        }
        return null
    },

    editfunction: function(e, d) {
        const { post } = d
        const editlink = this.getPostEditLink({ post })
        this.historyPushFetch(editlink)
    },
    editicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)

        if (deleteorrestore && canEdit) {
            const editfunction = this.editfunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        editfunction(e, { post })
                    }}
                    onTouchStart={function() {
                    }}
                    className={style.cardrightbuttonicon}
                >
                    <EditIcon />
                </IconButton>
            )
        }
        return null
    },
    duplicatefetch: function() {
    },
    duplicatefunction: function(e, d) {

        const { construct } = this.props
        const {
            curlang = {}
        } = construct.props

        const {
            duplicatepostsubtitle = 'Duplicate',
            duplicatepostquestion = 'Are you sure want to duplicate your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const { post } = d

        const duplicatefetch = this.duplicatefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: duplicatepostsubtitle,
            text: duplicatepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = post
                data.title = data.title + ' (duplicated)'
                data.duplicate = true
                data.share = 'public'

                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                duplicatefetch(data, resetForm, invalidateForm, refs)

            }
        })

    },
    duplicateicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const canEdit = (author && author.id && me && me.id === author.id)

        if (deleteorrestore === 'delete' && canEdit) {
            const duplicatefunction = this.duplicatefunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        duplicatefunction(e, { post })
                    }}
                    onTouchStart={function() {
                    }}
                    className={style.cardrightbuttonicon}
                >
                    <DuplicateIcon />
                </IconButton>
            )
        }
        return null
    },
    publicicon: function({ post }) {
        const share = post.share
        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },

    contentcomponent: function({ post }) {
        if (post) {

            const { construct } = this.props
            const {
                curlang = {}, history, getthumb, getthumbstyle
            }
                = construct.props

            const customStyle = construct.props.style

            const { statusname, approveenable, banenable, deleted } = this.getPostStatus({ post })
            const me = this.getMe()
            const author = (post && post.author) ? post.author : null
            const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)
            const isAdmin = (me && me.isAdmin || me && me.isEditor)

            const posttype = this.getPostType()
            const approvefunction = this['approvefunction' + posttype.toLowerCase()]
            const banfunction = this['banfunction' + posttype.toLowerCase()]
            const historyPushFetch = this.historyPushFetch

            const {
                viewprofile = 'view profile'
            } = curlang

            const data = [
                {
                    value: function({ post, style }) {
                        if (post && post.secuser && post.secuser.id) {
                            return (
                                <div className={style.avatarinmeta}>
                                    <WapplrAvatar
                                        user={post.secuser}
                                        style={avs}
                                        type='list'
                                        enableUserLink={true}
                                        history={history}
                                        getthumb={getthumb}
                                        getthumbstyle={getthumbstyle}
                                    />
                                    <div className={style.textlink + ' ' + style.paddingasvalue}
                                         onTouchTap={function() {
                                             historyPushFetch('/user/' + post.secuser.id)
                                         }}
                                         onTouchStart={function() {
                                         }}>
                                        {viewprofile}
                                    </div>
                                </div>
                            )
                        }
                        return null
                    }
                },
                {
                    icon: <EyeIcon />, name: curlang.metastatusofreaded, value: function({ post }) {
                        const readed = (post.readed) ? curlang.metareaded : curlang.metaunread
                        return <div style={{ fontWeight: 'bold' }}
                                    className={(post.readed) ? customStyle.secondaryColor : customStyle.warningColor}>{readed}</div>
                    }
                },
                { icon: <TimeIcon />, name: curlang.metanotificationdate, value: 'publishedDate' }
            ]

            data.push(
                {
                    icon: <StatusIcon />, name: curlang.status, value: function(props) {

                        const { post = {}, style } = props
                        if (canEdit && deleted || canEdit && isAdmin) {
                            return (
                                <div>
                                    {(deleted || isAdmin) ?
                                        <div>
                                            {statusname}
                                        </div> : null
                                    }
                                    {(isAdmin && approveenable || isAdmin && banenable) ?
                                        <div className={style.innerButtons}>
                                            <AdminPost
                                                curlang={curlang}
                                                approveenable={approveenable}
                                                banenable={banenable}
                                                approvefunction={approvefunction}
                                                banfunction={banfunction}
                                                post={post}
                                            />
                                        </div> : null
                                    }
                                </div>
                            )

                        }
                        return null
                    }
                }
            )

            return (
                <MetaData
                    rowstyle={{ width: '50%' }}
                    post={post}
                    style={mds}
                    data={data}
                />
            )
        }
        return null
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },


    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    //Title
    getTitle: function() {

        const { construct } = this.props
        const {
            route,
            routes = {},
            curlang = {}
        } = construct.props

        const {
            userpostssubtitle = 'Posts',
            userpoststrashsubtitle = 'Deleted posts',
            usernotificationsssubtitle = 'Notifications',
            usernotificationsstrashsubtitle = 'Deleted notifications'
        } = curlang

        const {
            wapplruserapppostsslug = '/posts',
            wapplruserappnotificationsslug = '/notifications'
        } = routes

        let userpoststitle = 'User posts'

        if (route === wapplruserapppostsslug) userpoststitle = userpostssubtitle
        if (route === wapplruserapppostsslug + '/trash') userpoststitle = userpoststrashsubtitle
        if (route === wapplruserappnotificationsslug) userpoststitle = usernotificationsssubtitle
        if (route === wapplruserappnotificationsslug + '/trash') userpoststitle = usernotificationsstrashsubtitle

        return userpoststitle
    },
    clearNotificatiosNumber: function() {

        const { construct } = this.props
        const { history, updatefunctions } = construct.props
        const getHelpers = this.getHelpers
        const me = this.getMe()
        const { postsdata, isMounted } = this.state
        const author = (postsdata && postsdata.author) ? postsdata.author : null

        if (isMounted && author && author.id && me && me.id && me.id === author.id && me.notificationsnumber) {
            fetchs.clearnotificationsnumber({
                getHelpers: getHelpers,
                history: history,
                success: function() {
                    updatefunctions.me({
                        callback: function() {
                            //console.log("updated user")
                        }
                    })
                },
                fail: function() {
                    console.log('fail clear notifications')
                }
            })()
        }

    },
    onClick: function(e, data) {

        const { construct } = this.props
        const { history, updatefunctions } = construct.props
        const link = data.link
        const getHelpers = this.getHelpers
        const me = this.getMe()

        if (me && data && data.id && !data.readed && data.author && data.author.id && data.author.id === me.id) {
            fetchs.savenotification({
                getHelpers: getHelpers,
                history: history,
                success: function() {
                    updatefunctions.me({
                        callback: function() {
                            if (history && link) {
                                history.push(link)
                            }
                        }
                    })
                },
                fail: function() {
                    if (history && link) {
                        history.push(link)
                    }
                }
            })({
                id: data.id,
                readed: true
            })
        } else {
            if (history && link) {
                history.push(link)
            }
        }

        if (e) e.preventDefault()
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d = {}) {

        const { history } = this.props
        const { type, path, post } = d

        if (type === 'post') {
            this.onClick(e, post)
        }

        if (type === 'deletenotification') {
            this.deletefunction(e, { post })
        }
        if (type === 'restorenotification') {
            this.restorefunction(e, { post })
        }
        if (type === 'go' && path) {
            history.push(path)
        }

        if (e) e.preventDefault()
    },
    render: function() {

        const { construct } = this.props
        const { style, history, searchData, curlang = {}, getthumb, getthumbstyle } = construct.props
        const { notresultstext } = curlang

        const { store } = this.getHelpers()
        const historyHelpers = searchData.getHistoryHelpers()
        const state = this.state
        const setRef = this.setRef
        const touchEnd = this.touchEnd
        const getPostStatus = this.getPostStatus

        const me = this.getMe()
        const title = this.getTitle()

        return (
            <div className={style.subpagebg}>
                {(title) ?
                    <div className={style.subheadercontainer}>
                        <Subheader className={style.subheader}>
                            {title}
                        </Subheader>
                    </div> : null
                }
                <div className={style['page-padding']}>
                    {(state.posts && state.posts.length) ?
                        <div>
                            <List>
                                {(state.posts.map(function(post, i) {

                                    const { deleteorrestore } = getPostStatus({ post })
                                    const mynotification = !!(me && post.author && post.author.id && me.id === post.author.id)
                                    const secondaryUser = (mynotification && post.secuser && post.secuser && post.secuser.id) ? post.secuser : null
                                    const showeditbuttons = (mynotification)
                                    const defaulticon = (post.readed) ? <EyeIcon /> : <ActionInfoIcon />
                                    const deleteOrRestoreNotificationType = (deleteorrestore !== 'delete') ? 'restore' : 'delete'
                                    const icon = (showeditbuttons) ?
                                        <IconButton
                                            touch={true}
                                            onTouchTap={function(e) {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                touchEnd(e, 'click', 'click', {
                                                    type: deleteOrRestoreNotificationType + 'notification',
                                                    post: post
                                                })
                                            }}
                                            className={style.iconButtonSvgAbsolute}
                                            onTouchStart={function() {
                                            }}
                                            tooltip={(deleteOrRestoreNotificationType === 'delete') ? curlang.deletetooltip : curlang.restoretooltip}>
                                            {(deleteOrRestoreNotificationType === 'delete') ? <DeleteIcon /> :
                                                <PublisIcon />}
                                        </IconButton> :
                                        <div>{defaulticon}</div>

                                    return (
                                        <ListItem
                                            key={i}
                                            className={(post.readed) ? style.seenlistitem : style.unseenlistitem}
                                            leftAvatar={
                                                <WapplrAvatar
                                                    user={(secondaryUser) ? secondaryUser : post.author}
                                                    style={avs}
                                                    type='menu'
                                                    enableUserLink={false}
                                                    history={history}
                                                    getthumb={getthumb}
                                                    getthumbstyle={getthumbstyle}
                                                    className={'menulist'}
                                                />
                                            }
                                            primaryText={post.title}
                                            secondaryText={
                                                <p>
                                                    <span
                                                        style={{ color: darkBlack }}>{'Értesítés dátuma: ' + post.publishedDate}</span><br />
                                                    További információ
                                                </p>
                                            }
                                            secondaryTextLines={2}
                                            rightIcon={icon}
                                            onTouchTap={function(e) {
                                                touchEnd(e, 'click', 'click', { type: 'post', post: post })
                                            }}
                                            onTouchStart={function() {
                                            }}
                                        />
                                    )
                                }))}
                            </List>
                            {(state.isMounted) ?
                                <Pagination
                                    onClick={this.paginationOnClick}
                                    ref={function(e) {
                                        setRef('pagination', e)
                                    }}
                                    infiniteScroll={true}
                                    style={fs}
                                    className={style['page-subheader']}
                                    history={history}
                                    historyHelpers={historyHelpers}
                                    data={state.postsdata}
                                    store={store}
                                /> : null
                            }
                        </div>
                        :
                        <NoMatches style={nms} notFoundText={notresultstext} />
                    }
                    <Wapplrdialog setRef={setRef} className={style.dialog} />
                    <Wapplrsnackbar ref={function(e) {
                        setRef('snack', e)
                    }} />
                </div>
            </div>
        )
    }
})
